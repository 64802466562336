/* eslint-disable camelcase */
import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { graphql, Link, withPrefix } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Layout from '../../../components/layout';
import Rule from '../../../components/rule';
import Section from '../../../components/section';
import NotFoundPage from '../../404';

export const query = graphql`
  query QueryProductPricing($id: String) {
    mdx(id: { eq: $id }, fileAbsolutePath: { regex: "/src/mdx/products/" }) {
      slug
      frontmatter {
        title
        headline
        tagline
        parent
        urls {
          docs
        }
        pricing {
          plan_id
          name
          price_monthly_paid_monthly
          price_monthly_paid_yearly
          description
        }
      }
    }
  }
`;

const PriceCard = ({ plan }) => {
  const {
    plan_id,
    name,
    description,
    price_monthly_paid_monthly,
    price_monthly_paid_yearly,
  } = plan;

  return (
    <div
      key={plan_id}
      data-plan-id={plan_id}
      className="p-4 text-gray-600  w-full md:w-1/2  lg:w-1/3 dark:text-gray-400 product"
    >
      <div className="flex lg64 flex-col p-8 bg-white rounded-lg dark:bg-gray-800 sm:flex-row">
        <div className="flex-grow text-gray-900 dark:text-gray-100">
          <h2 className="mb-3 text-lg font-medium title-font">{name}</h2>

          <div className="my-4">
            <span className="text-2xl" data-toggle="monthly">
              ${price_monthly_paid_monthly}
              <span className="text-base">
                {price_monthly_paid_monthly === 0 ? (
                  <>
                    {' '}
                    / forever
                    <div className="text-sm py-2">&nbsp;</div>
                  </>
                ) : (
                  <>
                    {' '}
                    / month
                    <div className="text-sm py-2">billed monthly</div>
                  </>
                )}
              </span>
            </span>{' '}
            <span className="text-2xl" data-toggle="yearly">
              ${price_monthly_paid_yearly}
              <span className="text-base">
                {price_monthly_paid_yearly === 0 ? (
                  <>
                    {' '}
                    / forever
                    <div className="text-sm py-2">&nbsp;</div>
                  </>
                ) : (
                  <>
                    {' '}
                    / month <br />
                    <div className="text-sm py-2">billed yearly</div>
                  </>
                )}
              </span>
            </span>
          </div>

          <div>{description}</div>
        </div>
      </div>
    </div>
  );
};

const ProductPricingPage = ({ data }) => {
  if (!data.mdx) {
    // for blog & policies
    return null;
  }

  const [checked, setChecked] = useState(true);
  const handleChange = () => {
    setChecked((checked) => !checked);
  };

  const { frontmatter, slug } = data.mdx;

  const { title, headline, parent, tagline } = frontmatter;

  const pricing = frontmatter?.pricing || null;

  const seoTitle = `Pricing for ${title} in ${parent}`;
  const seoDescription = `${seoTitle} | ${headline} | Paid monthly or yearly`;

  return parent === 'monday.com' && pricing ? (
    <>
      <GatsbySeo
        language="en"
        title={seoTitle}
        description={seoDescription}
        openGraph={{
          url: `https://www.dsapps.dev/products/${slug}pricing/`,
          title: seoTitle,
          description: seoDescription,
        }}
      />
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <script
          src={withPrefix('js/products-pricing.js')}
          type="text/javascript"
        />
      </Helmet>

      <Layout pageTitle={`Pricing - ${title}`}>
        <Section>
          <section className="text-gray-600 dark:text-gray-400 body-font">
            <div className="container flex md:flex-row flex-col items-center">
              <div className="lg:flex-grow flex flex-col items-start md:text-left text-left">
                <h2 className="mb-1 font-sans text-xs font-medium tracking-widest text-blue-500 title-font">
                  <Link
                    to={`/products/${slug}`}
                    className="uppercase animate-underline"
                  >
                    Back
                  </Link>
                </h2>
                <Rule />
                <h1 className="my-4 text-2xl text-gray-900 dark:text-gray-100 sm:text-4xl title-font">
                  {title}
                </h1>
                <h2 className="dark:text-gray-200 my-8 font-medium leading-relaxed">
                  {tagline}
                </h2>
                <h2 className="my-4 text-xl text-gray-900 dark:text-gray-100 sm:text-4xl title-font">
                  Pricing
                </h2>
                {/* <div className="my-4">
                  <p>
                    Pricing has not been enabled yet in the monday.com apps.
                  </p>
                  <p>
                    This page represents the pricing that will be applied when
                    pricing is enabled in the future.
                  </p>
                </div> */}

                <div className="mb-4">
                  <p className="text-lg mt-0">
                    <strong>14 days trial, then...</strong>
                  </p>
                </div>

                <div className="my-4 pricing-toggles">
                  <label
                    htmlFor="pricing-toggle-monthly"
                    className="text-gray-900 dark:text-gray-100 p-3"
                  >
                    <input
                      type="radio"
                      id="pricing-toggle-monthly"
                      name="pricing-toggle"
                      value="monthly"
                      defaultChecked={checked}
                      onChange={handleChange}
                      className="peer"
                    />
                    <span className="px-2 pricing">Monthly</span>
                  </label>
                  <label
                    htmlFor="pricing-toggle-yearly"
                    className="text-gray-900 dark:text-gray-100 p-3"
                  >
                    <input
                      type="radio"
                      id="pricing-toggle-yearly"
                      name="pricing-toggle"
                      value="yearly"
                      className="peer"
                    />
                    <span className="px-2 pricing">Yearly</span>
                  </label>
                </div>
                <section className="body-font">
                  <div className="container flex flex-wrap py-4 mx-auto">
                    <div
                      className="flex flex-wrap "
                      data-show-pricing="monthly"
                    >
                      {pricing.map((plan) => (
                        <PriceCard plan={plan} />
                      ))}
                    </div>
                  </div>
                </section>
                <div>
                  <p>
                    These subscription plans match your monday.com subscription
                    in a one-to-one manner.
                  </p>
                  <p className="mb-0">For example:</p>
                  <ul>
                    <li>6 seats of monday.com requires 6 seats of the app.</li>
                    <li>
                      10 seats of monday.com requires 10 seats of the app.
                    </li>
                  </ul>
                </div>
                {/* // Thsi just creates a CSV to paste here:
                https://gist.github.com/dvdsmpsn/aa47d27adac60ad4dc14cf806739be11 */}
                {/* <section>
                  <pre className="border p-4 rounded-md bg-grey-100 dark:bg-blue-900 overflow-auto hover:overflow-scroll">
                    {
                      'plan_id,name,description,price_monthly_paid_monthly,price_monthly_paid_yearly\n'
                    }
                    {pricing.map(
                      ({
                        plan_id,
                        name,
                        description,
                        price_monthly_paid_monthly,
                        price_monthly_paid_yearly,
                      }) => (
                        <>
                          {plan_id},{name},{description},
                          {price_monthly_paid_monthly},
                          {price_monthly_paid_yearly}
                          {'\n'}
                        </>
                      )
                    )}
                  </pre>
                </section> */}
              </div>
            </div>
          </section>
        </Section>
      </Layout>
    </>
  ) : (
    <>
      <GatsbySeo noindex />
      <NotFoundPage />
    </>
  );
};

export default ProductPricingPage;
